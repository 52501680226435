import type { Exchange } from '@urql/core'
import {
    makeFetchBody,
    makeFetchOptions,
    makeFetchSource,
    makeFetchURL,
} from '@urql/core/internal'
import compress from 'graphql-query-compress'
import { filter, merge, mergeMap, pipe, share, takeUntil } from 'wonka'

export const fetchExchangeWithCompression: Exchange = ({ forward }) => {
    return (ops$) => {
        const sharedOps$ = share(ops$)
        const fetchResults$ = pipe(
            sharedOps$,
            filter((operation) => {
                return (
                    operation.kind === 'query' || operation.kind === 'mutation'
                )
            }),
            mergeMap((operation) => {
                const body = makeFetchBody(operation)
                if (body.query) body.query = compress(body.query)

                return pipe(
                    makeFetchSource(
                        operation,
                        makeFetchURL(operation, body),
                        makeFetchOptions(operation, body)
                    ),
                    takeUntil(
                        pipe(
                            sharedOps$,
                            filter(
                                (op) =>
                                    op.kind === 'teardown' &&
                                    op.key === operation.key
                            )
                        )
                    )
                )
            })
        )

        const forward$ = pipe(
            sharedOps$,
            filter((operation) => {
                return (
                    operation.kind !== 'query' && operation.kind !== 'mutation'
                )
            }),
            forward
        )

        return merge([fetchResults$, forward$])
    }
}
