import { AssertionFailure } from '@getstep/sdk/dist/util/Assert'
import type { SSRExchange } from '@urql/core/dist/types/exchanges/ssr'
import { retryExchange } from '@urql/exchange-retry'
import { fetch } from 'cross-undici-fetch'
import type {
    DefinitionNode,
    DocumentNode,
    OperationDefinitionNode,
} from 'graphql'
import LRUCache from 'lru-cache'
import type {
    Client,
    CombinedError,
    DebugEventTypes,
    Exchange,
    Operation,
} from 'urql'
import {
    cacheExchange,
    createClient,
    dedupExchange,
    errorExchange,
    ssrExchange,
} from 'urql'
import { map, pipe } from 'wonka'

import { memoized } from '../lib/cache/memoized'
import { extendConsole } from '../shared/logging/console'
import { isDevelopment } from '../shared/util'
import { isBrowser, isPreviewMode, isTests } from '../shared/util/env'
import { LocalLink, stripAppPrefix } from '../shared/util/link'
import { fetchExchangeWithCompression } from './customFetchExchange'

// const TWO_MINUTES_IN_MS = 2 * 60 * 1000
const ONE_MINUTES_IN_MS = 60000

// min time to wait in ms before retrying a failed request
const CONTENTFUL_MIN_RETRY_DELAY_MS = 2500
// max time to wait in ms before retrying a failed request
const CONTENTFUL_MAX_RETRY_DELAY_MS = 15000
// max number of attempts to retry a failed request
const CONTENTFUL_MAX_RETRY_ATTEMPTS = 3

export type ContentfulClient = Client & {
    ssr: SSRExchange
    reset(reason: any): void
}

/**
 * Manages the Contentful GraphQL API clients on server and returns one global client in browser.
 *
 * Server-side clients are cached per route.
 * ### Why not request-based
 * Since Contentful data is static, we don't need to fetch it for each request, this would be a waste of resources.
 * We can cache the client for a period of time, and reuse it for the next request.
 *
 * ### Why not one global client
 * However, global client for everything would bloat up the document size, since the cache is serialized into JSON.
 * So we split the caches and...store them in THE GREAT CACHE (read this with a Scottish accent).
 * So it's technically uses still one global cache, but it's distributed.
 *
 * ### Why LRU cache
 * We have a LOT of pages. E.g. r/[code] is generated for every referral code.
 * So we only need to store the ones we need most.
 */
export const getOrCreateContentfulClient = (
    url?: string,
    initialState?: any
) => {
    if (!isTests() && !isBrowser() && !url) {
        throw new AssertionFailure(
            'can only return global Contentful client in browser or tests'
        )
    }

    return getOrCreateClient(
        cacheKeyFromURL(url ?? 'global'),
        initialState
    ) as ContentfulClient
}

/**
 * Strips any unnecessary prefixes from the given path.
 * @returns bare page path used for cache key
 */
export const cacheKeyFromURL = (url: string) =>
    stripAppPrefix(new LocalLink({ base: url }).pathname)

/**
 * Returns the name of the GraphQL operation.
 */
export function getOperationName(document: DocumentNode) {
    const definition = document.definitions.find(
        ({ kind }) => kind === 'OperationDefinition'
    ) as OperationDefinitionNode
    return definition.name?.value
}

const dedupFragmentsExchange: Exchange =
    ({ forward }) =>
    (operations$) =>
        forward(
            pipe(
                operations$,
                map((operation: Operation) => {
                    const { query, variables } = operation
                    const { definitions } = query

                    const usedFragments = new Set<string>()

                    const deduped = definitions.filter(
                        (definition: DefinitionNode) => {
                            if (definition.kind !== 'FragmentDefinition') {
                                return true
                            }

                            const { name } = definition

                            if (usedFragments.has(name.value)) {
                                return false
                            }

                            usedFragments.add(name.value)
                            return true
                        },
                        []
                    )

                    return {
                        ...operation,
                        variables: { ...variables, preview: isPreviewMode() },
                        query: { ...query, definitions: deduped },
                    }
                })
            )
        )

const cache = new LRUCache<string, ContentfulClient>({
    max: isDevelopment() && !isTests() ? 1 : 5,
    ttl: ONE_MINUTES_IN_MS,
    ttlAutopurge: true,
})

const getOrCreateClient = memoized(
    () => cache,
    (key, initialState: any) => {
        const ssr = ssrExchange({
            isClient: isBrowser(),
            initialState,
            staleWhileRevalidate: true,
        })

        const logger = extendConsole(`gql~${key}`)

        const client = createClient({
            url: process.env.NEXT_PUBLIC_CONTENTFUL_URI as string,
            suspense: true,
            maskTypename: true,
            fetch,
            exchanges: [
                cacheExchange,
                dedupFragmentsExchange,
                dedupExchange,
                ssr,
                errorExchange({
                    onError: (error: CombinedError) => {
                        if (!error?.graphQLErrors) return

                        // if preview content is enabled, we neped to filter out unresolved link errors from contentful
                        error.graphQLErrors = error.graphQLErrors.filter(
                            (e) =>
                                (
                                    e.extensions?.['contentful'] as {
                                        code: string
                                    }
                                )?.code !== 'UNRESOLVABLE_LINK'
                        )
                    },
                }),
                retryExchange({
                    initialDelayMs: CONTENTFUL_MIN_RETRY_DELAY_MS,
                    maxDelayMs: CONTENTFUL_MAX_RETRY_DELAY_MS,
                    randomDelay: true,
                    maxNumberAttempts: CONTENTFUL_MAX_RETRY_ATTEMPTS,
                    retryIf: (error: CombinedError) => {
                        if (!error?.graphQLErrors) return false

                        const hitRateLimit = error.graphQLErrors.some(
                            (e) =>
                                (
                                    e.extensions?.['contentful'] as {
                                        code: string
                                    }
                                )?.code === 'RATE_LIMIT_EXCEEDED'
                        )

                        logger.warn('hit contentful rate limit', {
                            error,
                        })

                        return hitRateLimit
                    },
                }),
                fetchExchangeWithCompression,
                // fetchExchange,
            ],
            fetchOptions: () => ({
                headers: {
                    authorization: `Bearer ${process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN}`,
                },
            }),
        })

        /**
         * Logging during dev to help with debugging graphql calls
         */
        if (isDevelopment() && process.env.DEBUG === 'true') {
            client.subscribeToDebugTarget?.(({ operation, data, type }) => {
                const { query, variables } = operation
                const operationName = getOperationName(query)

                switch (type as keyof DebugEventTypes) {
                    case 'fetchRequest': {
                        return logger.info(
                            operationName,
                            'variables:\n',
                            variables
                        )
                    }

                    case 'fetchSuccess': {
                        return logger.info(
                            operationName,
                            'data:\n',
                            data.value.data
                        )
                    }

                    case 'fetchError': {
                        return logger.error(operationName, data.value)
                    }

                    case 'cacheHit': {
                        return logger.info(operationName, 'cacheHit')
                    }

                    case 'cacheInvalidation': {
                        return logger.warn(
                            operationName,
                            'invalidated',
                            data.typenames
                        )
                    }
                }
            })
        }

        return new Proxy(client, {
            get: (target, prop) => {
                if (prop === 'ssr') {
                    return ssr
                }

                if (prop === 'reset') {
                    return () => {
                        cache.clear()
                    }
                }

                return target[prop]
            },
        }) as ContentfulClient
    }
)
