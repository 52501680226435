export interface NavigationLink {
    label: string
    href: string
    category?: string
    items?: NavigationLink[]
}

export type NavigationLinks = NavigationLink[]
export type NestedNavigationLinks = {
    [key: string]: NavigationLink[]
}

export const MAIN_NAVIGATION_LINKS: NavigationLinks = [
    {
        label: 'Benefits',
        href: '',
        items: [
            {
                label: 'Build Credit',
                href: '/build-credit',
            },
            {
                label: 'Save',
                href: '/save',
            },
            {
                label: 'Direct Deposit',
                href: '/direct-deposit',
            },
            {
                label: 'Rewards',
                href: '/rewards',
            },
            {
                label: 'Invest',
                href: '/investing',
            },
        ],
    },
    {
        label: 'Step Mobile',
        href: '/step-mobile',
    },
    {
        label: 'Money 101',
        href: '/education',
    },
    {
        label: 'Families',
        href: '/families',
    },
    {
        label: 'Security',
        href: '/security',
    },
    {
        label: 'About',
        href: '/about',
    },
    {
        label: 'FAQ',
        href: '/faq',
    },
]

export const FOOTER_NAVIGATION_LINKS: NavigationLinks = [
    // Company
    {
        label: 'About',
        href: '/about',
        category: 'Company',
    },
    {
        label: 'Careers',
        href: '/careers',
        category: 'Company',
    },
    {
        label: 'Press',
        href: '/press',
        category: 'Company',
    },
    {
        label: 'Money 101 Blog',
        href: '/money-101',
        category: 'Company',
    },
    {
        label: 'Step Merch Store',
        href: 'https://shop.step.com',
        category: 'Company',
    },
    // Legal
    {
        label: 'Policies',
        href: '/policies',
        category: 'Legal',
    },
    {
        label: 'Privacy Policy',
        href: '/policies/privacy',
        category: 'Legal',
    },
    {
        label: 'COPPA',
        href: '/policies/coppa',
        category: 'Legal',
    },
    {
        label: 'Cookie Declaration',
        href: '/cookie-declaration',
        category: 'Legal',
    },
    // Support
    {
        label: 'FAQ',
        href: '/faq',
        category: 'Support',
    },
    {
        label: 'Contact Us',
        href: 'mailto:hello@step.com',
        category: 'Support',
    },
    {
        label: 'Partner With Us',
        href: '/partners',
        category: 'Support',
    },
    {
        label: 'Status',
        href: 'https://status.step.com/',
        category: 'Support',
    },
]
